import React from 'react';

const Home=()=>{
    localStorage.clear();
    return (
        <div>
            <div className="backgroundImageHomePage"></div>
            <div className='borderedName'>
                <hr className='firsthr'/>
                <h2>Thai Spicy</h2>
                <h4>Healthy <i className='glyphicon glyphicon-dot'></i> Tasty <span className='glyphicon glyphicon-dot'></span> Delicious</h4>
                <hr style={{opacity: 1}}/>
                <div className="d-flex flex-column">
                    <a href="https://thaispicy.foodorder.site/rs/menu_home.action?resInput=RES703" target="_blank" rel="noreferrer" style={{ padding: 10, backgroundColor: "#eee", color: "green", border: "1px solid maroon", fontWeight: "bolder", borderRadius: 5, textAlign: "center", textDecoration: "none"}}>ORDER ONLINE</a>
                    <a href="/menu.pdf" target="_blank" rel="noreferrer" style={{ padding: 10, backgroundColor: "#eee", color: "red", border: "1px solid maroon", fontWeight: "bolder", borderRadius: 5, textAlign: "center", textDecoration: "none"}}>DOWNLOAD MENU</a>
                </div>
            </div>
        </div>
    )
}
export default Home;
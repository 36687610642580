// const groups = [
//     "Appetizer", "Soup", "Salad", "Fried Rice Entree", "Noodle Entree", "Wok Entree", "Curry", 
//     "Special Dish",
// ]

const groups = [
    {"name": "Appetizer",},
    {"name": "Soup",},
    {"name": "Salad",},
    {
        "name": "Fried Rice Entree",
        "hasMeatChoice": true,
    },
    {
        "name": "Noodle Entree",
        "hasMeatChoice": true,
    },
    {
        "name": "Special Dish",
        "hasRiceChoice": true,
    },
    {
        "name": "Wok Entree",
        "hasMeatChoice": true,
        "hasRiceChoice": true,
    },
    {
        "name": "Curry",
        "hasMeatChoice": true,
        "hasRiceChoice": true,
    }
]

const appetizer=[
    {"name":"Gyoza", "price":8.99, "description": "Pan-fried Pork or Vegetable dumpling and served with soy-sesame sauce", "notes":""},
    {"name":"Chicken Satay", "price":11.99, "description": "Marinated chicken skewer served with peanut sauce and side of cucumber salad", "notes":""},
    {"name":"Veggie Spring Roll", "price":5.99, "description": "Crispy vegetarian roll served with sweet chili dipping", "notes":""},
    {"name":"Chicken Spring Roll", "price":6.99, "description": "Mixed chicken and vegetables rolled inside a crispy wrapper served with sweet chili dipping", "notes":""},
    {"name":"Crab Rangoon", "price":8.99, "description": "Crispy wonton stuffed with cream cheese, crabstick, carrot, and green onion served with sweet plum sauce", "notes":""},
    {"name":"Shrimp Tempura", "price":9.99, "description": "Crispy fried japanese style shrimp served with tempura sauce", "notes":""},
    {"name":"Fried Tofu", "price":5.99, "description": "Deep fried bean curd tofu served with sweet chili sauce and crushed peanut", "notes":""},
    {"name":"Edamame", "price":5.99, "description": "Steamed soy bean sprinkled with salt", "notes":""},
    {"name":"Fried chicken wonton", "price":7, "description": "Deep fried chicken wonton served with homemade soy sesame sauce", "notes":""},
]
const noodleentree=[
    {"name":"Pad Thai", "price":12.99, "dinnerPrice":16.99, "description": "Stir-fried rice noodle with your choice of meat, egg, scallion, bean sprout, and crushed peanut", "notes":""},
    {"name":"Crispy Pad Thai", "price":12.99, "dinnerPrice":16.99, "description": "Stir-fried crispy noodle with your choice of meat, bean sprout, scallion, egg, and crushed peanut", "notes":""},
    {"name":"Pad See Ewe", "price":12.99, "dinnerPrice":16.99, "description": "Stir- fried wide rice noodle with your choice of meat, egg, broccoli, and cabbage and carrot", "notes":""},
    {"name":"Pad Woon Sen", "price":12.99, "dinnerPrice":16.99, "description": "Stir-fried Bean thread noodle with your choice of meat, egg, baby corn, celery, carrot, cabbage scallion and cilantro on top", "notes":""},
    {"name":"Bangkok Noodle", "price":12.99, "dinnerPrice":16.99, "description": "Stir-fried wide rice noodle with your choice of meat, onion, carrot, tomata,scallion, and cilantro. Served with chili sauce", "notes":""},
    {"name":"Drunken Noodle", "price":12.99, "dinnerPrice":16.99, "description": "Stir-fried wide rice noodle with your choice of meat, mushroom, bell pepper, onion, carrot and basil leaf", "notes":""},
    {"name":"Kao Soi", "price":null, "dinnerPrice":16.99, "description": "Northern Thai style curry noodle with fried egg noodle on top and your choice of meat", "notes":""},
    {"name":"Thai Noodle Soup", "price":12.99, "dinnerPrice":16.99, "description": "Rice noodle, fish balls, bean sprout, cilantro, scallion, and served in delicious broth", "notes":""},
    {"name":"Guay Tiew Tom Yum", "price":null, "dinnerPrice":16.99, "description": "Spicy noodle soup with minced pork, meat ball, bean sprout, cilantro, crushed peanut and lime juice", "notes":""},
    {"name":"Tom yum Ramen", "price":null, "dinnerPrice":16.99, "description": "The famous Thai hot and sour soup with ramen noodle, mushroom, cilantro, and tomato", "notes":""},
]
const wokentree=[
    {"name":"Assorted Vegetables", "price":12.99, "dinnerPrice":16.99, "description": "Sautéed assorted vegetable in a light brown sauce and your choice of meat", "notes":""},
    {"name":"Cashew Nut", "price":12.99, "dinnerPrice":16.99, "description": "Stir fried onion, scallion, carrot, zucchini, bell pepper, and cashew nut in roasted chili sauce with your choice of meat", "notes":""},
    {"name":"Fresh Ginger", "price":12.99, "dinnerPrice":16.99, "description": "Stir fried fresh ginger with mushroom, pepper, onion, celery, baby corn, carrot, and scallion in ginger sauce with your choice of meat", "notes":""},
    {"name":"Hot Basil", "price":12.99, "dinnerPrice":16.99, "description": "Thai traditional Basil stir fried with choice of ground Chicken or Pork, bell pepper, onion, green bean, basil leaf in chili basil sauce", "notes":"Top with fried egg: $1.50"},
    {"name":"Pepper Onion", "price":12.99, "dinnerPrice":16.99, "description": "Stir fried bell pepper and onion in brown garlic sauce with your choice of meat", "notes":""},
    {"name":"Spicy Eggplant", "price":12.99, "dinnerPrice":16.99, "description": "Sautéed Eggplant, carrot, onion, bell pepper in chili basil sauce with your choice of meat", "notes":""},
    {"name":"Sweet & Sour", "price":12.99, "dinnerPrice":16.99, "description": "Stir fried onion, scallion, tomato, cucumber, carrot, and pineapple in sweet and sour sauce with your choice of meat", "notes":""},
    {"name":"Teriyaki", "price":12.99, "dinnerPrice":16.99, "description": "Your choice of meat with teriyaki sauce and steamed vegetable", "notes":""},
    {"name":"Thai Spice", "price":12.99, "dinnerPrice":16.99, "description": "Stir fried with broccoli, carrot, bell peper, baby corn, bamboo, and cabbage in spicy red sauce with your choice of meat", "notes":""},
]
const friedriceentree=[
    {"name":"Crab Meat Fried Rice", "price":null, "dinnerPrice":18.95, "description": "Fried rice with egg, crab meat, carrot, pea, scallion and cilantro", "notes":""},
    {"name":"Pineapple Fried Rice", "price":12.99, "dinnerPrice":16.99, "description": "Fried rice with your choice of meat, pineapple, egg, onion, pea, carrot, scallion, raisin, cashew nut with curry powder", "notes":""},
    {"name":"Spicy Basil Fried Rice", "price":12.99, "dinnerPrice":16.99, "description": "Fried rice with your choice of meat, bell pepper, onion, carrot, and basil leaf in chili garlic sauce", "notes":""},
    {"name":"Thai Fried Rice", "price":12.99, "dinnerPrice":16.99, "description": "Fried rice with your choice of meat, egg, green pea, onion, carrot, broccoli, scallion, and tomato", "notes":""},
]
const curry=[
    {"name":"Green Curry", "price":12.99, "dinnerPrice":16.99, "description": "Eggplant, bamboo shoot, green bean, bell pepper, carrot and basil leaf in coconut green curry sauce with your choice of meat", "notes":""},
    {"name":"Mango Curry", "price":12.99, "dinnerPrice":16.99, "description": "Mango, pineapple, onion, and carrot in yellow curry sauce with your choice of meat", "notes":""},
    {"name":"Massaman Curry", "price":12.99, "dinnerPrice":16.99, "description": "Potato, onion, carrot, and roasted peanut in coconut curry sauce with your choice of meat", "notes":""},
    {"name":"Panang Curry", "price":12.99, "dinnerPrice":16.99, "description": "A light red, slightly sweeter curry paste with kaffir lime leaf and coconut milk, with carrot, green bean, and your choice of meat", "notes":""},
    {"name":"Red Curry", "price":12.99, "dinnerPrice":16.99, "description": "Eggplant, bamboo shoot, green bean, carrot, bell pepper and basil leaf in coconut red curry sauce with your choice of meat", "notes":""},
    {"name":"Yellow Curry", "price":12.99, "dinnerPrice":16.99, "description": "Potato, carrot, and onion in coconut yellow curry sauce with your choice of meat", "notes":""},
]
const specialdish=[
    {"name":"Three Flavor Fish", "dinnerPrice":17.99, "description": "Fried fish, chopped bell pepper, onion and cilantro in three flavor chili sauce", "notes":""},
    {"name":"Crispy Duck", "dinnerPrice":24.99, "description": "Half boneless crispy duck with sweet and tanky sauce and steamed vegetables", "notes":""},
    {"name":"Seafood Hot Pot", "dinnerPrice":26.99, "description": "Combination of seafood in hot and sour soup with lemongrass, tomato, mushroom, cilantro. Served in hot pot", "notes":""},
    {"name":"Pad Cha Seafood", "dinnerPrice":26.99, "description": "Sautéed combination of seafood with carrot, onion, bell pepper, fresh peppercorn and kaffir lime leaf in chili basil sauce", "notes":""},
    {"name":"Pla Tod Gra Tiem", "dinnerPrice":18.99, "description": "Fried fish, topped with fried garlic served with steamed vegetable and spicy chili lime sauce", "notes":""},
    {"name":"Hot Basil Fish", "dinnerPrice":17.99, "description": "Fried fish with carrot, bell pepper, and mushroom in chili basil sauce", "notes":""},
]
const soup=[
    {"name":"Tom Yum", "price":6.99, "description": "Thai hot and sour with mushroom, tomato,cilantro and your choice of meat", "notes":"w/ chicken or tofu +$0.00, w/ Shrimp +$1.00"},
    {"name":"Tom Kha", "price":6.99, "description": "Rich and creamy coconut-infused broth, with mushroom, tomato, cilantro, scallion, and your choice of meat", "notes":"w/ chicken +$0.00, w/ Shrimp +$1.00"},
    {"name":"Miso Soup", "price":5, "description": "Soft tofu, seaweed, green onion in miso broth", "notes":""},
    {"name":"Wonton Soup", "price":5.99, "description": "Chicken filled wontons, scallions and cilantro", "notes":""},
    {"name":"Veggie Tofu Soup", "price":5, "description": "Assorted vegetable and tofu in vegetable broth", "notes":""},
]
const salad=[
    {"name":"House Salad", "price":6.99, "description": "Mixed romaine and iceberg lettuce, cucumber, tomato, served with Ginger Dressing", "notes":""},
    {"name":"Green Papaya Salad (Som Tum)", "price":10.99, "description": "Shredded unripe papaya, carrot, green bean, tomato, and roasted peanut in spicy garlic lime sauce", "notes":""},
    {"name":"Larb", "price":13.99, "description": "Ground pork or chicken with, onion, scallion, lemongrass, and mixed with lime sauce. Fried shallot on top", "notes":""},
    {"name":"Num Tok", "price":14.99, "description": "Grilled pork or beef tossed with chill, onion, scallion,dry chill and mixed with lime sauce", "notes":""},
]

const config = {
    groups,
    appetizer,
    noodleentree,
    // noodlesoup,
    wokentree,
    friedriceentree,
    specialdish,
    curry,
    soup,
    salad,
};

export default config;